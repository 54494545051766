<template>
	<div class="animatedLogo" title="Cascades">
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 1080 1080"
			style="enable-background:new 0 0 1080 1080;"
			xml:space="preserve"
		>
		<path d="M0,118.304c369.783,0,508.302,226.064,595.87,421.739C666.304,697.435,818.478,885.261,1080,885.261"/>
		<path d="M0,118.304c369.783,0,435.199,276.219,502.826,421.696c72.02,154.926,169.565,421.783,511.304,421.783"/>
		</svg>
	</div>
</template>

<style scoped>
.animatedLogo {
	--function: cubic-bezier(0.4, 0, 0.4, 1);
	position: relative;
	display: grid;
	place-items: center;
	width: 150px;
	margin: 1rem 0;
}

path {
	fill: none;
	stroke-dasharray: 1500;
	stroke-dashoffset: 1500;
	stroke-miterlimit: 10;
	stroke-width: 200;
	animation: logoFlow 1s var(--function) forwards;
	
	&:nth-of-type(1) {
		stroke: var(--color-primary-light);
	}

	&:nth-of-type(2) {
		stroke: var(--color-primary);
		animation-delay: 0.1s;
	}
}

@media (prefers-reduced-motion) {
	path {
		stroke-dashoffset: 0;
	}
}
@media not (prefers-reduced-motion) {
	@keyframes logoFlow {
		0%   { stroke-dashoffset: 1500; }
		100% { stroke-dashoffset: 50; }
	}
}
</style>
