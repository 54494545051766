import { SubscriptionTier } from './subscription';

export enum SessionEventType {
	Click,
	Nav,
	Resize,
	Begin,
	End
}

export class SessionEvent {
	public event: SessionEventType;
	public info: string;
	public time: number;

	constructor (event: SessionEventType, info: string) {
		this.event = event;
		this.info = info;
		this.time = Date.now();
	}
}

export interface SessionRaw {
	journey: Array<SessionEvent>;
	tier: SubscriptionTier;
	time: number;
}
