import { createApp } from 'vue';
import '@/css/root.css';
import App from '@/App.vue';
import { createPinia } from 'pinia';
import router from '@/router';
import { registerSW } from 'virtual:pwa-register';
import { Session } from '@/modules/session';
import { useDialogStore } from '@/modules/stores/dialog';
import { useFlagsStore } from '@/modules/stores/flags';
import { useOfflineProjectStore } from '@/modules/stores/offlineProject';
import { useOnlineProjectStore } from '@/modules/stores/onlineProject';
import { useThemeStore } from '@/modules/stores/theme';
import { useUserStore } from '@/modules/stores/user';
import { version } from '@/version';

// Service worker.
const updateInterval = 60000;
const updateSW = registerSW({
	onNeedRefresh (): void {
		document.getElementById('refreshPrompt')?.classList.add('show');
		(document.querySelector('#refreshPrompt button.refresh') as HTMLButtonElement | undefined)?.addEventListener('click', () => { updateSW(); });
	},
	onOfflineReady (): void {
		console.info('Offline content ready.');
	},
	onRegisteredSW (url, registration): void {
		if (!registration) return;
		setInterval(async () => {
			if (registration.installing || !navigator) return;
			if (('connection' in navigator) && !navigator.onLine) return;
			const response = await fetch(url, {
			  cache: 'no-store',
			  headers: {
				'cache': 'no-store',
				'cache-control': 'no-cache',
			  }
			});
			if (response?.status === 200) await registration.update();
		}, updateInterval);
	}
});

// Version.
console.info(`Cascades Version ${version}`);

// Session logging (only run in production).
const productionMode = import.meta.env.PROD;
if (!productionMode) console.info('Cascades Dev Mode');
if (productionMode) {
	let session = new Session(router);
	session.begin();
	document.addEventListener('visibilitychange', () => {
		if (document.visibilityState === 'hidden') {
			session.end();
			const analyticsData = JSON.stringify(session.export());
			navigator.sendBeacon('/analytics', analyticsData);
		} else if (document.visibilityState === 'visible' && !session.active) {
			session = new Session();
			session.begin();
		}
	});
}

// Stores.
const app = createApp(App);
const pinia = createPinia();
export const userStore = useUserStore(pinia);
export const dialogStore = useDialogStore(pinia);
export const flagsStore = useFlagsStore(pinia);
export const offlineProject = useOfflineProjectStore(pinia);
export const onlineProject = useOnlineProjectStore(pinia);
export const theme = useThemeStore(pinia);

app.use(pinia);
app.use(router);
app.mount('#app');
