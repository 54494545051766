import { watch, type WatchSource, type WatchStopHandle } from 'vue';

/**
 * Wait for the truthy-ness of a reactive source to change.
 * @param source The reactive value.
 * @param value *(Optional)* The value to wait for.
 * @param timeout *(Optional)* The maximum time (in milliseconds) to wait, after which the promise will reject.
 * @returns The value of the source, post change.
 */
export function awaitChange <T = any> (source: WatchSource<T>, value?: boolean, timeout?: number): Promise<T> {
	function getSourceValue (): T {
		return typeof source === 'function' ? source() : source.value;
	}

	return new Promise<T>((resolve, reject) => {
		const initial = Boolean(getSourceValue());
		if (initial === value) return resolve(getSourceValue());
		let unwatch: WatchStopHandle | undefined;
		let timeoutId: number | undefined;
		if (typeof timeout === 'number') {
			timeoutId = window.setTimeout(() => {
				unwatch?.();
				reject();
			}, timeout);
		}
		unwatch = watch(
			source,
			newValue => {
				if (typeof value === 'boolean') {
					if (Boolean(newValue) === value) {
						unwatch?.();
						resolve(getSourceValue());
						window.clearTimeout(timeoutId);
					}
				} else {
					if (Boolean(newValue) !== initial) {
						unwatch?.();
						resolve(getSourceValue());
						window.clearTimeout(timeoutId);
					}
				}
			},
			{ once: true }
		);
	});
}

export function formatDate (date: number): string {
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const newDate = new Date(date);
	return `${months[newDate.getMonth()]} ${newDate.getUTCDate()}, ${newDate.getFullYear()}`;
}

export function formatDateIso (date: number): string {
	return new Date(date).toISOString();
}

/**
 * Creates a promise that waits for a given delay (in ms) before resolving.
 * @param delay
 */
export function wait (delay: number): Promise<void> {
	return new Promise<void>((resolve) => window.setTimeout(resolve, delay));
}
