<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { userStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import { gravatarFromHash } from '@/modules/gravatar';
import { type Project, type PublicUserInfo } from '@/structures';
import { formatDate, formatDateIso } from '@/utilities';
import Icon from '@/components/Icon.vue';
import LikeButton from '@/components/LikeButton.vue';

const props = defineProps({
	large: { type: Boolean, required: false, default: false },
	loading: { type: Boolean, required: false, default: false },
	project: { type: String, required: false },
	search: { type: String, required: false }
});

const data = ref<Project | undefined>(undefined);
const author = ref<PublicUserInfo | undefined>();
const authorLink = computed(() => (author.value?.handle.startsWith('@') ? `/${author.value.handle}` : `/profile/${author.value?.handle || authorUid.value}`));
const authorUid = ref('');
const loadingData = ref(false);
const loadingThumbnail = ref(false);

const searchCondition = computed((): boolean => {
	if (!data.value || !props.search) return true;
	return data.value.title.toLowerCase().includes(props.search.toLowerCase());
});

async function load (): Promise<void> {
	if (loadingData.value || !props.project) return;
	loadingData.value = true;
	loadingThumbnail.value = true;
	data.value = await Firebase.publicProjects.get(props.project);
	if (data.value?.owner) {
		authorUid.value = data.value.owner;
		author.value = await Firebase.publicUsers.get(authorUid.value);
	}
	loadingData.value = false;
}

void load();
watch(() => props.project, () => void load());
</script>

<template>
	<RouterLink
		v-if="searchCondition"
		:to="`/project/${project}`"
		class="projectCard"
		:class="{ large, loading: loadingData || loadingThumbnail || loading || !project }"
		data-journey-id="ProjectCard"
	>
		<div class="preview">
			<img
				:src="data?.thumbnail ?? ''"
				alt=""
				@load="loadingThumbnail = false"
			>
			<div class="overlay"></div>
		</div>
		<div class="options" @click.stop.prevent>
			<LikeButton
				:project
				data-journey-id="ProjectCardLike"
			/>
			<RouterLink
				v-if="userStore.uid === data?.owner"
				class="button"
				title="Edit"
				:to="'/create/' + project"
				data-journey-id="ProjectCardEdit"
			><Icon outline>edit</Icon></RouterLink>
			<slot name="options"></slot>
		</div>
		<div class="info">
			<h3>{{ data?.title }}</h3>
			<p>
				<RouterLink :to="authorLink" data-journey-id="ProjectCardAuthor">
					<img :src="author?.profilePic ? gravatarFromHash(author.profilePic) : ''" width="20" height="20" alt="">
					{{ author?.displayName }}
				</RouterLink>
				<time v-if="data?.datePublished" :datetime="formatDateIso(data.datePublished)"> • {{ formatDate(data.datePublished) }}</time>
			</p>
		</div>

		<div class="skeleton">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	</RouterLink>
</template>

<style scoped>
.projectCard {
	display: grid;
	grid-template-columns: 1fr 2rem;
	grid-template-rows: auto 4rem;
	gap: 0.2em;
	width: 100%;
	margin-top: 1rem;
	color: inherit;
	text-decoration: none;
}
.projectCard.loading {
	pointer-events: none;
}
.projectCard.loading > *:not(.skeleton) {
	opacity: 0;
}

.projectCard > .skeleton {
	position: absolute;
	inset: 0;
	display: none;
	grid-template-columns: 1.5rem auto;
	grid-template-rows: auto 1.5rem 1.5rem;
	gap: 0.4em;
	padding: 0.5ch;
	opacity: 0.2;
	transform: translateY(-0.2em);
	
	& span {
		background-color: #888;
		background: linear-gradient(90deg, #888 60%, #bbb 95%, #888);
		background-attachment: fixed;
		background-position: 0 0;
		background-repeat: repeat;
		background-size: 200vmax 200vmax;
		border-radius: var(--border-radius);
		animation: loadingPulse 2.5s linear infinite;

		&:nth-of-type(1) {
			grid-area: 1 / 1 / 2 / 3;
			margin-inline: -0.5ch;
		}
		
		&:nth-of-type(2) {
			grid-area: 2 / 1 / 3 / 3;
			margin: 0.2ch 0;
		}

		&:nth-of-type(3) {
			border-radius: 50%;
		}
		
		&:nth-of-type(4) {
			margin: 0.2ch 0;
		}
	}
}
.projectCard.loading > .skeleton {
	display: grid;
}

.projectCard > .preview {
	grid-area: 1 / 1 / 2 / 3;
	display: block;
	width: 100%;
	aspect-ratio: 16 / 9;
	background-color: #888;
	border-radius: var(--border-radius);
	box-shadow: 0 2px 10px -4px #0007;
	transition: 0.2s ease transform;

	& img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: inherit;
	}

	& .overlay {
		position: absolute;
		inset: 0;
		border-radius: inherit;
		box-shadow: var(--popout-shadow-medium);
		mix-blend-mode: overlay;
		pointer-events: none;
	}
}
.projectCard:not(.loading):hover > .preview {
	transform: translateY(-0.5rem);
}

.projectCard > .options {
	grid-area: 1 / 2 / 2 / 3;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-end;
	gap: 1ch;
	padding: 0.5ch;
	color: white;
	opacity: 0;
	transition: 0.1s ease opacity;

	&::before {
		content: '';
		position: absolute;
		inset: 0 0 0 -2em;
		display: block;
		background: linear-gradient(270deg, #0006, transparent);
		border-radius: 0 var(--border-radius) var(--border-radius) 0;
		transition: 0.2s ease transform;
	}
}
.projectCard.loading > .options {
	display: none;
}
.projectCard:is(:hover, :focus-within) > .options {
	opacity: 1;
}
.projectCard:hover > .options::before {
	transform: translateY(-0.5rem);
}

.projectCard > .info {
	grid-area: 2 / 1 / 3 / 3;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.2em;
	padding: 0.5ch 1ch;
	border-radius: var(--border-radius);
}
.projectCard.loading > .info::after {
	inset: 1ch 0 0 0;
}

.projectCard > .info h3 {
	width: 100%;
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.projectCard > .info p {
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5ch;
	width: 100%;
	margin: 0;
	font-size: 0.8rem;
	white-space: nowrap;
	overflow: hidden;
}

.projectCard > .info p > a {
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	max-width: 23ch;
	padding: 0.2em;
	padding-bottom: 0.3em;
	color: inherit;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	border-radius: var(--border-radius);
	outline: unset;
	transition: 0.1s ease background-color;
	overflow: hidden;
}
.projectCard > .info p > a:is(:hover, :focus-visible) {
	background-color: var(--color-button-background);
}
.projectCard.loading > .info p > a {
	opacity: 0;
}

.projectCard > .info p img {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	aspect-ratio: 1;
	margin-right: 0.5ch;
	border-radius: 50%;
}

@media screen and (min-width: 50rem) {
	.projectCard.large {
		grid-template-columns: auto 2rem auto;
		grid-template-rows: 3.2rem 6.8rem;
	}
	.projectCard.large > .preview {
		grid-area: 1 / 1 / 3 / 3;
	}
	.projectCard.large > .info {
		grid-area: 1 / 3 / 3 / 4;
		gap: 0.5em;
		font-size: 1rem;
		width: 40ch;
	}
	.projectCard.large > .info h3 {
		content: 'Project Card';
		margin: 0;
		font-size: 1.1rem;
		font-weight: 400;
	}
	.projectCard.large > .info p {
		font-size: 0.85rem;
	}
}
</style>
