export default `.pond {
	position: absolute;
	inset: 0;
	display: grid;
	place-items: center;
	background-color: #48627b;
	background:
		radial-gradient(circle at 0% 100%, #4c7397, transparent),
		linear-gradient(0deg, #66c9ff, #66c9ff);
}

.pond .leaf {
	position: absolute;
	width: 100%;
	aspect-ratio: 1;
	background: radial-gradient(circle at 100% 0%, transparent 35%, #fff1 45%, transparent 46%, #ffffff08 65%, transparent 66%, #ffffff04 90%, transparent 91%);
	filter: drop-shadow(-10px -10px 30px #0004);
}
.pond .leaf::before {
	content: '';
	position: absolute;
	inset: 0;
	background-color: #577b48;
	background: radial-gradient(circle at 100% 0%, #0b2714, #577b48 40%, #0b2714 56%, transparent calc(56% + 1px));
	clip-path: polygon(
		0 0,
		100% 0,
		105% 100%,
		95% 30%,
		45% 100%,
		0 100%
	);
}

.pond .frog {
	position: absolute;
	display: grid;
	place-items: center;
	width: 40%;
	aspect-ratio: 1.4;
	transform: translateY(-8%) rotate(37deg) scale(0.8);
}

.pond .frog .leftLeg {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 60%;
	filter: brightness(0.95);
}
.pond .frog .leftLeg .thigh {
	position: absolute;
	top: 0;
	right: 0;
	display: grid;
	place-items: center;
	width: 90%;
	height: 26%;
	transform: translateY(-40%) rotate(-48deg);
	transform-origin: 100% 50%;
}
.pond .frog .leftLeg .thigh::after {
	content: '';
	position: absolute;
	inset: 0;
	background: radial-gradient(#b6d62e, #8cd41c, #6fa228);
	border-radius: 50%;
	box-shadow: -5px -5px 15px #0004 inset;
}
.pond .frog .leftLeg .thigh .calf {
	position: absolute;
	z-index: -1;
	left: 0;
	display: grid;
	place-items: center;
	width: 74%;
	height: 70%;
	transform: rotate(-15deg);
	transform-origin: 0% 50%;
}
.pond .frog .leftLeg .thigh .calf::after {
	content: '';
	position: absolute;
	inset: 0;
	background:radial-gradient(#b6d62e, #8cd41c, #6fa228);
	border-radius: 50%;
	box-shadow: -5px -5px 15px #0004 inset;
	filter: brightness(0.95);
}
.pond .frog .leftLeg .thigh .calf .foot {
	position: absolute;
	z-index: -1;
	right: 0;
	width: 130%;
	height: 70%;
	background: radial-gradient(#be9906, #be9906);
	border-radius: 50%;
	box-shadow: -5px -5px 15px #0004 inset;
	transform: rotate(20deg);
	transform-origin: 100% 50%;
}

.pond .frog .rightLeg {
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 60%;
	filter: brightness(0.85);
}
.pond .frog .rightLeg .thigh {
	position: absolute;
	top: 0;
	left: 0;
	display: grid;
	place-items: center;
	width: 90%;
	height: 26%;
	transform: translateY(-40%) rotate(25deg);
	transform-origin: 0% 50%;
}
.pond .frog .rightLeg .thigh::after {
	content: '';
	position: absolute;
	inset: 0;
	background: radial-gradient(#b6d62e, #8cd41c, #6fa228);
	border-radius: 50%;
	box-shadow: -5px -5px 15px #0004 inset;
}
.pond .frog .rightLeg .thigh .calf {
	position: absolute;
	right: 0;
	display: grid;
	place-items: center;
	width: 74%;
	height: 70%;
	transform: rotate(30deg);
	transform-origin: 100% 50%;
}
.pond .frog .rightLeg .thigh .calf::after {
	content: '';
	position: absolute;
	inset: 0;
	background:radial-gradient(#b6d62e, #8cd41c, #6fa228);
	border-radius: 50%;
	box-shadow: -5px -5px 15px #0004 inset;
}
.pond .frog .rightLeg .thigh .calf .foot {
	position: absolute;
	z-index: -1;
	left: 0;
	width: 130%;
	height: 70%;
	background: radial-gradient(#be9906, #be9906);
	border-radius: 50%;
	box-shadow: -5px -5px 15px #0004 inset;
	transform: rotate(-45deg);
	transform-origin: 0% 50%;
}

.pond .frog .leftArm {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 37%;
	height: 60%;
	filter: brightness(0.95);
}
.pond .frog .leftArm .bicep {
	position: absolute;
	top: 10%;
	right: 0;
	display: grid;
	place-items: center;
	width: 80%;
	height: 15%;
	transform: translateY(-40%) rotate(-42deg);
	transform-origin: 100% 50%;
}
.pond .frog .leftArm .bicep::after {
	content: '';
	position: absolute;
	inset: 0;
	background: radial-gradient(#b6d62e, #8cd41c, #6fa228);
	border-radius: 50%;
	box-shadow: -5px -5px 15px #0004 inset;
}
.pond .frog .leftArm .bicep .foot {
	position: absolute;
	top: 0;
	left: 0;
	display: grid;
	place-items: center;
	width: 90%;
	aspect-ratio: 1;
	background:
		radial-gradient(7% 32% at 50% 62%, #be9906 70%, #be9906 calc(100% - 1px), transparent 100%),
		radial-gradient(9% 10% at 15% 65%, #be9906 40%, #b2b56d calc(100% - 1px), transparent 100%),
		radial-gradient(9% 10% at 50% 90%, #be9906 40%, #b2b56d calc(100% - 1px), transparent 100%),
		radial-gradient(9% 9% at 82% 72%, #be9906 40%, #b2b56d calc(100% - 1px), transparent 100%);
	transform: translate(-45%, -27%) rotate(55deg);
	transform-origin: 50% 36%;
}
.pond .frog .leftArm .bicep .foot::before {
	content: '';
	position: absolute;
	width: 10%;
	height: 51%;
	background: radial-gradient(#be9906 70%, #be9906 calc(100% - 1px), transparent 100%);
	border-radius: 50%;
	transform: translate(40%, 20%) rotate(51deg);
	transform-origin: 50% 0%;
}
.pond .frog .leftArm .bicep .foot::after {
	content: '';
	position: absolute;
	width: 10%;
	height: 51%;
	background: radial-gradient(#be9906 70%, #be9906 calc(100% - 1px), transparent 100%);
	border-radius: 50%;
	transform: translate(-5%, 20%) rotate(-41deg);
	transform-origin: 50% 0%;
}

.pond .frog .rightArm {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 37%;
	height: 60%;
	filter: brightness(0.95);
}
.pond .frog .rightArm .bicep {
	position: absolute;
	top: 10%;
	left: 0;
	display: grid;
	place-items: center;
	width: 80%;
	height: 15%;
	transform: translateY(-40%) rotate(40deg);
	transform-origin: 0% 50%;
}
.pond .frog .rightArm .bicep::after {
	content: '';
	position: absolute;
	inset: 0;
	background: radial-gradient(#b6d62e, #8cd41c, #6fa228);
	border-radius: 50%;
	box-shadow: -5px -5px 15px #0004 inset;
}
.pond .frog .rightArm .bicep .foot {
	position: absolute;
	top: 0;
	right: 0;
	display: grid;
	place-items: center;
	width: 90%;
	aspect-ratio: 1;
	background:
		radial-gradient(7% 32% at 50% 62%, #be9906 70%, #be9906 calc(100% - 1px), transparent 100%),
		radial-gradient(9% 10% at 15% 65%, #be9906 40%, #b2b56d calc(100% - 1px), transparent 100%),
		radial-gradient(9% 10% at 50% 90%, #be9906 40%, #b2b56d calc(100% - 1px), transparent 100%),
		radial-gradient(9% 9% at 82% 72%, #be9906 40%, #b2b56d calc(100% - 1px), transparent 100%);
	transform: translate(42%, -25%) rotate(-75deg);
	transform-origin: 50% 36%;
}
.pond .frog .rightArm .bicep .foot::before {
	content: '';
	position: absolute;
	width: 10%;
	height: 51%;
	background: radial-gradient(#be9906 70%, #be9906 calc(100% - 1px), transparent 100%);
	border-radius: 50%;
	transform: translate(40%, 20%) rotate(51deg);
	transform-origin: 50% 0%;
}
.pond .frog .rightArm .bicep .foot::after {
	content: '';
	position: absolute;
	width: 10%;
	height: 51%;
	background: radial-gradient(#be9906 70%, #be9906 calc(100% - 1px), transparent 100%);
	border-radius: 50%;
	transform: translate(-5%, 20%) rotate(-41deg);
	transform-origin: 50% 0%;
}

.pond .frog .body {
	position: absolute;
	width: 38%;
	height: 100%;
	background:
		radial-gradient(#fff6, transparent 70%),
		radial-gradient(circle at 50% 70%, #b6d62e88, #59c62577 70%, transparent),
		radial-gradient(circle at 100% 0, #6fa228, #b6d62e, #8cd41c);
	background-blend-mode:
		overlay,
		overlay,
		overlay;
	border-top-left-radius: 50% 75%;
	border-top-right-radius: 50% 75%;
	border-bottom-right-radius: 50% 25%;
	border-bottom-left-radius: 50% 25%;
	box-shadow:
		-10px -10px 10px #0004 inset,
		-10px -10px 30px #0004 inset,
		-10px -10px 30px #0004 inset,
		10px 10px 30px #0002;
}

.pond .frog .leftEye {
	position: absolute;
	bottom: 10%;
	width: 16%;
	aspect-ratio: 1;
	background: radial-gradient(100% 80% at 50% 60%, #111, black 40%, #bbdcf7 41%, #bbdcf7 47%, #8cd41c 49%, #6fa228);
	border-radius: 50%;
	box-shadow: 0 0 30px -15px #0008;
	transform: translateX(-95%) rotate(50deg) scaleY(0.9);
}
.pond .frog .rightEye {
	position: absolute;
	bottom: 10%;
	width: 16%;
	aspect-ratio: 1;
	background: radial-gradient(100% 80% at 50% 60%, #111, black 40%, #bbdcf7 41%, #bbdcf7 47%, #8cd41c 49%, #6fa228);
	border-radius: 50%;
	box-shadow: 0 0 30px -15px #0008;
	transform: translateX(95%) rotate(-50deg) scaleY(0.9);
}

.pond .frog .nose {
	content: ':';
	position: absolute;
	bottom: 10%;
	aspect-ratio: 1;
	color: black;
	box-shadow: 0 0 30px -15px #0008;
	transform: translate(100%, 95%) rotate(87deg) scaleY(1);
}

.pond .lighting {
	position: absolute;
	inset: 0;
	background: radial-gradient(circle at 100% 0%, #fff1, transparent);
	mix-blend-mode: overlay;
	pointer-events: none;
}

.pond .filter {
	position: absolute;
	inset: 0;
	background-image: url('/noise.png');
	backdrop-filter: contrast(1.1) brightness(0.95) saturate(1.05);
	pointer-events: none;
}
`;
