import { compressToBase64, decompressFromBase64 } from 'lz-string';
import type { DataConverterRX, DataConverterTX } from './meta';
import { calculateLinesOfCode, matchedAssign } from './other';

/**
 * A user-created comment.
 */
export class Comment {
	author: string;
	date: number;
	message: string;
	pinned = false;
	shadowed = false;

	constructor (author: string, message: string) {
		this.author = author;
		this.date = Date.now();
		this.message = message;
	}

	static fromFirestore: DataConverterRX<Comment> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new Comment('', ''), data);
	}

	static toFirestore: DataConverterTX<Comment> = (comment) => {
		return { ...comment };
	}
}

/**
 * The IDs of today's featured projects.
 */
export type FeaturedProjects = Array<string>;

/**
 * A CSS syntax variant.
 */
export enum Flavour {
	CSS = 'css',
	LESS = 'less',
	SCSS = 'scss',
	STYLUS = 'stylus'
}

/**
 * A user-created project.
 */
export class Project {
	backgroundAlpha = 1;
	backgroundColor = '#1e1e1e';
	canvasRatio = 1.3;
	compressedCss = '';
	dateCreated = Date.now();
	dateEdited = Date.now();
	datePublished = 0;
	description = '';
	editorRatio = 1;
	flavour = Flavour.CSS;
	likes = 0;
	linesOfCode = 0;
	live = false;
	owner: string;
	public = false;
	shadowed = false;
	tags = new Array<string>();
	title = 'Untitled';
	thumbnail = '';
	views = 0;
	zoom = 1;

	get css (): string {
		return decompressFromBase64(this.compressedCss) ?? '';
	}
	set css (value: string) {
		this.compressedCss = value ? compressToBase64(value) : '';
		this.linesOfCode = calculateLinesOfCode(value);
	}

	constructor (owner: string) {
		this.owner = owner;
	}

	static fromFirestore: DataConverterRX<Project> = (snapshot) => {
		const data = snapshot.data();
		const project = matchedAssign(new Project(''), data);
		project.compressedCss = data.css ?? '';
		return project;
	}

	static toFirestore: DataConverterTX<Project> = (project) => {
		const data: Partial<Project> = { ...project };
		delete data.compressedCss;
		data.css = project.compressedCss;
		return data;
	}
}

/**
 * The default settings for a user-created project.
 */
export const projectDefaults = new Project('');

/**
 * A single version of a user-created project.
 */
export class Version {
	backgroundAlpha = 1;
	backgroundColor = '#1e1e1e';
	compressedCss = '';
	dateCreated = Date.now();
	dateEdited = Date.now();
	datePublished = 0;
	flavour = Flavour.CSS;
	likes = 0;
	linesOfCode = 0;
	locked = false;
	public = false;
	title = 'Untitled';
	thumbnail = '';
	views = 0;

	get css (): string {
		return decompressFromBase64(this.compressedCss) ?? '';
	}
	set css (value: string) {
		this.compressedCss = value ? compressToBase64(value) : '';
		this.linesOfCode = calculateLinesOfCode(value);
	}

	static fromFirestore: DataConverterRX<Version> = (snapshot) => {
		const data = snapshot.data();
		const version = matchedAssign(new Version(), data);
		version.compressedCss = data.css ?? '';
		return version;
	}

	static toFirestore: DataConverterTX<Version> = (version) => {
		const data: Partial<Version> = { ...version };
		delete data.compressedCss;
		data.css = version.compressedCss;
		return data;
	}

	/**
	 * Create a new version based on the latest version of a project.
	 * @param latest The latest version of a project.
	 * @returns A new version based on the latest version.
	 */
	static fromLatest (latest: Version): Version {
		const version = new Version();
		version.backgroundAlpha = latest.backgroundAlpha;
		version.backgroundColor = latest.backgroundColor;
		version.compressedCss = latest.compressedCss;
		version.flavour = latest.flavour;
		version.linesOfCode = latest.linesOfCode;
		version.title = latest.title;
		version.thumbnail = latest.thumbnail;
		return version;
	}
}
