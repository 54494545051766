import { ref } from 'vue';
import { initializeApp } from 'firebase/app';
import { EmailAuthProvider, getAuth, onAuthStateChanged, reauthenticateWithCredential, signOut, updateProfile } from 'firebase/auth';
import type { Unsubscribe } from 'firebase/auth';
import { getFirestore, collection, onSnapshot, doc, documentId, getDoc, getDocs, where, query, orderBy, limit, startAfter } from 'firebase/firestore';
import type { QueryFieldFilterConstraint, QuerySnapshot } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { type AppConfig, type AccountLink, AccountSettings, Collection, type FeaturedProjects, Project, type ProjectLink } from '@/structures';
import { publicUsers, projectLinks, publicProjects, publicComments, publicCollections, publicVersions } from '@/modules/caches';
import { Batcher } from './batch';
import { Operation } from './operations';
import { flags } from '@/modules/stores/flags';
import { flagsStore, onlineProject, theme, userStore } from '@/main';

export default class Firebase {
	private static firebaseConfig = {
		apiKey: 'AIzaSyDVHbeVE1lOMGfRhIczbegsXMptb3YvSNY',
		authDomain: 'css-canvas.firebaseapp.com',
		projectId: 'css-canvas',
		storageBucket: 'css-canvas.appspot.com',
		messagingSenderId: '33965155888',
		appId: '1:33965155888:web:a805564ea908dd9fc9aff5'
	};
	// Services.
	public static app = initializeApp(this.firebaseConfig);
	public static auth = getAuth(this.app);
	public static database = getFirestore(this.app);
	public static functions = getFunctions(this.app);
	// Data collections.
	public static config = ref<AppConfig | undefined>(undefined);
	public static projects = ref(new Map<string, Project>());
	public static selectedProject = ref<Project | undefined>(undefined);
	public static selectedProjectId = ref<string | undefined>(undefined);
	public static collections = ref(new Map<string, Collection>());
	public static settings = ref<AccountSettings | undefined>(new AccountSettings());
	public static publicUsers = publicUsers;
	public static projectLinks = projectLinks;
	public static publicProjects = publicProjects;
	public static publicComments = publicComments;
	public static publicVersions = publicVersions;
	public static publicCollections = publicCollections;
	// Other.
	public static loading = ref(true);
	public static projectBatcher = new Batcher(this.database, 'projects');
	public static collectionBatcher = new Batcher(this.database, 'collections');
	private static authUnsubscribe: Unsubscribe | undefined;
	private static flagsUnsubscribe: Unsubscribe | undefined;
	private static projectsUnsubscribe: Unsubscribe | undefined;
	private static collectionsUnsubscribe: Unsubscribe | undefined;
	private static settingsUnsubscribe: Unsubscribe | undefined;

	static {
		// Retrieve the app configuration.
		getDoc(doc(this.database, 'app/config')).then((document) => {
			this.config.value = document.data() as AppConfig | undefined;
		});

		// Subscribe to feature flag changes.
		this.flagsUnsubscribe = onSnapshot(doc(this.database, 'app/flags'), (snapshot) => {
			const data = snapshot.data();
			if (!data) return;
			for (const flag of flags) {
				flagsStore[flag] = Boolean(data[flag]);
			}
		});

		// Authentication.
		this.authUnsubscribe = onAuthStateChanged(this.auth, async user => {
			this.auth = getAuth(this.app);
			userStore.set(user);
			console.info(user ? 'Logged in.' : 'Logged out.');
	
			// The user logged out.
			if (!user) {
				// Unsubscribe from data changes.
				this.projectsUnsubscribe?.();
				this.projectsUnsubscribe = undefined;
				this.collectionsUnsubscribe?.();
				this.collectionsUnsubscribe = undefined;
				this.settingsUnsubscribe?.();
				this.settingsUnsubscribe = undefined;
				// Clear user data from memory.
				this.projects.value.clear();
				this.selectedProject.value = undefined;
				this.selectedProjectId.value = undefined;
				onlineProject.reset();
				this.settings.value = new AccountSettings();
				this.loading.value = false;
				return;
			}
	
			// Otherwise, the user has logged in.
	
			// Set the loading state for the UI.
			this.loading.value = true;

			// Set the user's profile link.
			const link = (await getDoc(doc(this.database, `profileLinks/${user.uid}`))).data() as AccountLink | undefined;
			if (link?.link) {
				if (link.link.startsWith('@')) {
					userStore.vanityLink = link.link;
				} else {
					userStore.link = link.link;
				}
			}
	
			// Listen to changes in the user's document.
			this.settingsUnsubscribe = onSnapshot(doc(this.database, `users/${user.uid}`).withConverter(AccountSettings), async (documentSnapshot) => {
				this.settings.value = documentSnapshot.data();
				if (!this.settings.value) {
					await Operation.create(Operation.Type.User, {});
					return;
				}
				theme.current = this.settings.value.theme;
			});

			// Listen to changes in the user's projects collection.
			this.projectsUnsubscribe = onSnapshot(query(
				collection(this.database, `projects`).withConverter(Project),
				where('owner', '==', user.uid),
				orderBy('dateEdited', 'desc')
			), async (collectionSnapshot) => {
				// Load any changed projects.
				this.projects.value.clear();
				for (const document of collectionSnapshot.docs) {
					const data = document.data();
					if (data) this.projects.value.set(document.id, data);
				}
				// If no projects exist, create a blank one.
				if (user && this.projects.value.size <= 0) {
					await Operation.create(Operation.Type.Project, {});
				}
				// If no project is selected, or the selected project doesn't exist, select the first one in the list.
				if (this.projects.value.size > 0 && !this.projects.value.has(onlineProject.id)) {
					onlineProject.select(this.projects.value.keys().next().value);
				}
				// Set the loading state for the UI.
				this.loading.value = false;
			});

			// Listen to changes in the user's collections collection.
			this.collectionsUnsubscribe = onSnapshot(query(
				collection(this.database, `collections`).withConverter(Collection),
				where('owner', '==', user.uid),
				orderBy('title', 'asc')
			), async (collectionSnapshot) => {
				// Load any changed collections.
				this.collections.value.clear();
				collectionSnapshot.docs.forEach(document => {
					const data = document.data();
					if (data) this.collections.value.set(document.id, data);
				});
			});
		});
	}

	/**
	 * Update the display name for the currently logged in user.
	 * @param name The new display name.
	 */
	public static async updateDisplayName (name: string): Promise<void> {
		if (!userStore.raw || userStore.displayName === name) return;
		await updateProfile(userStore.raw, { displayName: name });
	}

	/**
	 * Logout of the current auth session.
	 */
	public static async logout (): Promise<void> {
		await signOut(Firebase.auth);
	}

	/**
	 * Re-authenticate the currently signed-in user.
	 * @param password The user's password.
	 * @returns `true` if re-authentication succeeded, `false` if not.
	 */
	public static async reauthenticate (password: string): Promise<boolean> {
		if (!userStore.raw || !userStore.raw.email) return false;
		try {
			const credential = EmailAuthProvider.credential(userStore.raw.email, password);
			const result = await reauthenticateWithCredential(userStore.raw, credential);
			return Boolean(result);
		} catch (error) {
			return false;
		}
	}

	/**
	 * Retrieve a random set of public projects.
	 * @param sortBy
	 * @param asIds
	 * @param filter
	 * @returns The set of projects.
	 */
	public static async getPublicProjects <T extends boolean> (sortBy: keyof Project, asIds: T, filter?: QueryFieldFilterConstraint): Promise<Array<T extends true ? string : Project>> {
		const publicProjects = new Array<[string, Project]>();
		const uids = new Set<string>();

		let documents: QuerySnapshot<Project | undefined>;
		if (filter) {
			documents = await getDocs(query(
				collection(Firebase.database, `projects`).withConverter(Project),
				where('public', '==', true),
				where('shadowed', '==', false),
				filter,
				orderBy(sortBy, 'desc')
			));
		} else {
			documents = await getDocs(query(
				collection(Firebase.database, `projects`).withConverter(Project),
				where('public', '==', true),
				where('shadowed', '==', false),
				orderBy(sortBy, 'desc')
			));
		}
		documents.forEach(document => {
			const data = document.data();
			if (!data) return;
			publicProjects.push([document.id, data]);
			const owner = data.owner;
			if (owner && typeof owner === 'string') {
				uids.add(owner);
			}
		});

		await Firebase.publicUsers.getMultiple(Array.from(uids));

		return (asIds ? publicProjects.map(document => document[0]) : publicProjects.map(document => document[1])) as Array<T extends true ? string : Project>;
	}

	/**
	 * Retrieve a random set of public projects by a related tag.
	 * @param sortBy
	 * @param asIds
	 * @param tag
	 * @returns The set of projects.
	 */
	public static async getPublicProjectsByTag <T extends boolean> (sortBy: keyof Project, asIds: T, tag: string): Promise<Array<T extends true ? string : Project>> {
		return await this.getPublicProjects(sortBy, asIds, where('tags', 'array-contains', tag));
	}

	/**
	 * Iterate over the most recently published projects in sets of `amount`.
	 * @param amount The number of projects to include in each iteration.
	 * @returns The set of project IDs at each iteration.
	 */
	public static async * getMostRecentPublicProjectsPaginated (amount = 1): AsyncGenerator<Array<string>, Array<string>> {
		let lastVisibleDocument;
		while (true) {
			const output = new Array<string>();
			const uids = new Set<string>();
			let documents;
			if (!lastVisibleDocument) {
				documents = await getDocs(query(
					collection(Firebase.database, `projects`).withConverter(Project), 
					where('public', '==', true),
					where('shadowed', '==', false),
					orderBy('datePublished', 'desc'),
					limit(amount)
				));
			} else {
				documents = await getDocs(query(
					collection(Firebase.database, `projects`).withConverter(Project), 
					where('public', '==', true),
					where('shadowed', '==', false),
					orderBy('datePublished', 'desc'),
					startAfter(lastVisibleDocument),
					limit(amount)
				));
			}
			lastVisibleDocument = documents.docs[documents.docs.length - 1];
			documents.forEach(document => {
				const data = document.data();
				if (!data) return;
				output.push(document.id);
				Firebase.publicProjects.store(document.id, data);
				if (data.owner && typeof data.owner === 'string') {
					uids.add(data.owner);
				}
			});

			await Firebase.publicUsers.getMultiple(Array.from(uids));

			yield output;
		}
	}

	/**
	 * Retrieve a given number of the most recently published projects.
	 * @param amount
	 * @param asIds
	 * @returns The set of projects.
	 */
	public static async getMostRecentPublicProjects <T extends boolean> (amount: number, asIds: T): Promise<Array<T extends true ? string : Project>> {
		const output = new Map<string, Project>();
		const uids = new Set<string>();

		const documents = await getDocs(query(
			collection(Firebase.database, `projects`).withConverter(Project), 
			where('public', '==', true),
			where('shadowed', '==', false),
			orderBy('datePublished', 'desc'),
			limit(amount)
		));
		documents.forEach(document => {
			const data = document.data();
			if (!data) return;
			output.set(document.id, data);
			Firebase.publicProjects.store(document.id, data);
			if (data.owner && typeof data.owner === 'string') {
				uids.add(data.owner);
			}
		});

		await Firebase.publicUsers.getMultiple(Array.from(uids));

		return (asIds ? Array.from(output.keys()) : Array.from(output.values())) as Array<T extends true ? string : Project>;
	}

	/**
	 * Retrieve today's featured projects.
	 * @param asIds
	 * @returns The IDs of the featured projects.
	 */
	public static async getFeaturedProjects <T extends boolean> (asIds: T): Promise<T extends true ? FeaturedProjects : Array<Project>> {
		const uids = new Set<string>();

		const featureDate = Firebase.config.value?.featureDate;
		if (!featureDate || typeof featureDate !== 'string') return [];

		const features = (await getDoc(doc(Firebase.database, `featuredProjects/${featureDate}`))).data()?.projects;
		if (!Array.isArray(features)) return [];

		const projectIds = features.filter((value) => value && typeof value === 'string');
		const projects = await Firebase.publicProjects.getMultiple(projectIds);
		for (const project of projects.values()) {
			uids.add(project.owner);
		}

		await Firebase.publicUsers.getMultiple(Array.from(uids));

		return (asIds ? Array.from(projects.keys()) : Array.from(projects.values())) as T extends true ? FeaturedProjects : Array<Project>;
	}

	/**
	 * Retrieve the short link for a given project.
	 * @param projectId
	 * @returns The short link, or `undefined` if the link could not be retrieved.
	 */
	public static async getProjectLink (projectId: string): Promise<string | undefined> {
		const linkDocuments = await getDocs(query(collection(Firebase.database, 'projectLinks'), where('projectId', '==', projectId)));
		const links = new Array<string>();
		linkDocuments.forEach((document) => {
			const data = document.data() as ProjectLink;
			if (data.link) {
				links.push(data.link);
				Firebase.projectLinks.store(data.link, projectId);
			}
		});
		if (links.length <= 0) {
			return;
		}
		return links[0];
	}

	/**
	 * Toggle the inclusion of a given project in a collection.
	 * If the project is present, it will be removed. If not, it will be added.
	 * @param collectionId The ID of the collection.
	 * @param projectId The ID of the project.
	 */
	public static toggleProjectInCollection (collectionId: string, projectId: string): void {
		if (!collectionId || !projectId) return;
		const coll = Firebase.collections.value.get(collectionId);
		if (!coll) return;
		const index = coll.projects.indexOf(projectId);
		if (index < 0) {
			coll.projects.push(projectId);
		} else {
			coll.projects.splice(index, 1);
		}
	}

	/**
	 * Wait for the result of an image download task to become available.
	 * This will timeout after 10 seconds.
	 * @param taskId The ID of the task to wait for.
	 * @returns The download URL of the image.
	 * @throws If the request timed out.
	 */
	public static async waitForImageResult (taskId: string): Promise<string> {
		return new Promise((resolve, reject) => {
			const unsubscribe = onSnapshot(
				query(
					collection(Firebase.database, 'services/image/results'),
					where(documentId(), '==', taskId)
				),
				snapshot => {
					if (snapshot.empty) return;
					const url: string = snapshot.docs[0].data()?.url;
					if (typeof url !== 'string') return;
					window.clearTimeout(timeout);
					unsubscribe();
					resolve(url);
				}
			);
			const timeout = window.setTimeout(() => {
				unsubscribe();
				reject();
			}, 15000);
		});
	}
}
