<script lang="ts" setup>
import { ref } from 'vue';

defineProps({
	label: { type: String, required: false }
});

const emit = defineEmits(['enter']);

const bridge = ref<HTMLDivElement | null>(null);
const pre = ref<HTMLDivElement | null>(null);
const post = ref<HTMLDivElement | null>(null);
let entering = false;

function gotoPre (event: KeyboardEvent): void {
	if (entering) return;
	if (!event.shiftKey) return;
	pre.value?.focus();
}

function gotoPost (event: KeyboardEvent): void {
	if (entering) return;
	if (event.shiftKey) return;
	post.value?.focus();
}

function enter (): void {
	entering = true;
	// TODO: Make this generic. It should be able to focus any focusable element.
	bridge.value?.querySelector('textarea')?.focus();
	emit('enter');
	entering = false;
}
</script>

<template>
	<div
		ref="bridge"
		class="focusBridge"
	>
		<div
			ref="pre"
			tabindex="0"
			:title="label"
			class="pre"
			@keydown.tab="gotoPost($event)"
			@keydown.enter.prevent="enter()"
		></div>
		<slot></slot>
		<div
			ref="post"
			tabindex="0"
			:title="label"
			class="post"
			@keydown.tab="gotoPre($event)"
			@keydown.enter.prevent="enter()"
		></div>
	</div>
</template>

<style scoped>
.focusBridge {
	display: contents;
	border-radius: inherit;
}

.pre,
.post {
	position: absolute;
	z-index: 2;
	inset: 0;
	border-radius: inherit;
	pointer-events: none;
}
</style>
