<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import Firebase from '@/modules/firebase/core';
import { gravatarFromHash } from '@/modules/gravatar';
import { type Collection, type Project, type PublicUserInfo } from '@/structures';

const props = defineProps({
	collection: { type: String, required: false },
	search: { type: String, required: false }
});

const data = ref<Collection | undefined>(undefined);
const thumbnail1 = ref<string | undefined>(undefined);
const thumbnail2 = ref<string | undefined>(undefined);
const thumbnail3 = ref<string | undefined>(undefined);
const author = ref<PublicUserInfo | undefined>();
const authorLink = computed(() => (author.value?.handle.startsWith('@') ? `/${author.value.handle}` : `/profile/${author.value?.handle || authorUid.value}`));
const authorUid = ref('');
const loading = ref(false);
const searchCondition = computed((): boolean => {
	if (!data.value || !props.search) return true;
	return data.value.title.toLowerCase().includes(props.search.toLowerCase());
});

async function load (): Promise<void> {
	if (loading.value || !props.collection) return;
	loading.value = true;
	data.value = await Firebase.publicCollections.get(props.collection);
	if (data.value?.owner) {
		authorUid.value = data.value.owner;
		author.value = await Firebase.publicUsers.get(data.value.owner);
	}
	if (data.value?.projects.length) {
		const operations = new Array<Promise<Project | undefined>>();
		for (let i = 0; i < 3; i++) {
			const projectId = data.value.projects[i];
			if (!projectId) continue;
			operations.push(Firebase.publicProjects.get(projectId));
		}
		const projects = await Promise.all(operations);
		thumbnail1.value = projects[0]?.thumbnail;
		thumbnail2.value = projects[1]?.thumbnail;
		thumbnail3.value = projects[2]?.thumbnail;
	}
	loading.value = false;
}

void load();
watch(() => props.collection, () => void load());
</script>

<template>
	<RouterLink
		v-if="searchCondition"
		:to="`/collection/${collection}`"
		class="collectionCard"
		:class="{ loading: loading || !collection }"
		data-journey-id="CollectionCard"
	>
		<div class="preview">
			<img v-if="thumbnail1" :src="thumbnail1" alt="">
			<img v-if="thumbnail2" :src="thumbnail2" alt="">
			<img v-if="thumbnail3" :src="thumbnail3" alt="">
		</div>
		<div class="banner">
			<p>Collection</p>
		</div>
		<div class="info">
			<h3>{{ data?.title }}</h3>
			<p>
				<RouterLink :to="authorLink" data-journey-id="CollectionCardAuthor">
					<img :src="author?.profilePic ? gravatarFromHash(author.profilePic) : ''" width="20" height="20" alt="">
					{{ author?.displayName }}
				</RouterLink>
				<span> • {{ data?.projects.length ?? 0 }} project{{ (data?.projects.length ?? 0) !== 1 ? 's' : '' }}</span>
			</p>
		</div>

		<div class="skeleton">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	</RouterLink>
</template>

<style scoped>
.collectionCard {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 4rem;
	width: 100%;
	margin-top: 1rem;
	color: inherit;
	text-decoration: none;
}
.collectionCard.loading {
	pointer-events: none;
}
.collectionCard.loading > *:not(.skeleton) {
	opacity: 0;
}

.collectionCard > .skeleton {
	position: absolute;
	inset: 0;
	display: none;
	grid-template-columns: 1.5rem auto;
	grid-template-rows: auto 1.5rem 1.5rem;
	gap: 0.4em;
	padding: 0.5ch;
	opacity: 0.2;
	transform: translateY(-0.2em);
	
	& span {
		background-color: #888;
		background: linear-gradient(90deg, #888 60%, #bbb 95%, #888);
		background-attachment: fixed;
		background-position: 0 0;
		background-repeat: repeat;
		background-size: 200vmax 200vmax;
		border-radius: var(--border-radius);
		animation: loadingPulse 2.5s linear infinite;

		&:nth-of-type(1) {
			grid-area: 1 / 1 / 2 / 3;
			margin-inline: -0.5ch;
		}
		
		&:nth-of-type(2) {
			grid-area: 2 / 1 / 3 / 3;
			margin: 0.2ch 0;
		}

		&:nth-of-type(3) {
			border-radius: 50%;
		}
		
		&:nth-of-type(4) {
			margin: 0.2ch 0;
		}
	}
}
.collectionCard.loading > .skeleton {
	display: grid;
}

.collectionCard > .preview {
	grid-area: 1 / 1 / 2 / 2;
	display: block;
	width: 100%;
	aspect-ratio: 16 / 9;
	background-color: #8882;
	border-radius: var(--border-radius);
	box-shadow: 0 2px 10px -4px #0007;
	transition: 0.2s ease transform;
	overflow: hidden;

	& img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: inherit;
		box-shadow: 0 0 20px -4px #000a;
	}
}
.collectionCard:not(.loading):hover:not(:has(a:hover)) > .preview {
	transform: translateY(-0.5rem);
}
.collectionCard > .preview > img:nth-of-type(2) {
	transform: translateX(30%);
}
.collectionCard > .preview > img:nth-of-type(3) {
	transform: translateX(64%);
}

.collectionCard > .banner {
	grid-area: 1 / 1 / 2 / 2;
	width: calc(100% - 1ch);
	height: fit-content;
	margin: auto 0.5ch -0.25ch;
	padding: 0.5ch;
	font-size: 0.8rem;
	text-align: center;
	background-color: var(--color-primary-harmony);
	border-radius: var(--border-radius);
	box-shadow: 0 0 10px -4px #0008;
}
.collectionCard.loading > .banner {
	display: none;
}

.collectionCard > .banner p {
	display: block;
	margin: 0;
}

.collectionCard > .info {
	grid-area: 2 / 1 / 3 / 2;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0.5ch 1ch;
	border-radius: var(--border-radius);
}
.collectionCard.loading > .info::after {
	inset: 1ch 0 0 0;
}

.collectionCard > .info h3 {
	width: 100%;
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.collectionCard > .info p {
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5ch;
	width: 100%;
	margin: 0;
	font-size: 0.8rem;
	white-space: nowrap;
	overflow: hidden;
}

.collectionCard > .info p > a {
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	max-width: 23ch;
	padding: 0.2em;
	padding-bottom: 0.3em;
	color: inherit;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	border-radius: var(--border-radius);
	outline: unset;
	transition: 0.1s ease background-color;
	overflow: hidden;
}
.collectionCard > .info p > a:is(:hover, :focus-visible) {
	background-color: var(--color-button-background);
}
.collectionCard.loading > .info p > a {
	opacity: 0;
}

.collectionCard > .info p img {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	aspect-ratio: 1;
	margin-right: 0.5ch;
	border-radius: 50%;
}
</style>
