<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { sendEmailVerification } from 'firebase/auth';
import { userStore } from '@/main';
import { awaitChange } from '@/utilities';
import DialogBox from '@/components/DialogBox.vue';

defineProps({ modelValue: { type: Boolean, required: true } });
const emit = defineEmits(['update:modelValue', 'success', 'error']);
const loading = ref(false);

const hide = (): void => emit('update:modelValue', false);

onMounted(async () => {
	loading.value = true;
	await awaitChange(() => userStore.raw, true);
	if (!userStore.raw) {
		emit('error');
		hide();
		return;
	};
	if (userStore.raw.emailVerified) {
		emit('success');
		hide();
		return;
	}
	await sendEmailVerification(userStore.raw);
	loading.value = false;
});
</script>

<template>
	<DialogBox
		title="Verify email address"
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="VerificationMenu"
	>
		<template v-slot:default>
			<form @submit.prevent="hide()">
				<p>Please follow the link in the email we just sent<template v-if="userStore.raw?.email"> to <i>{{ userStore.raw.email }}</i></template>.</p>
				<button type="submit" :disabled="loading" data-journey-id="VerificationMenuDone">
					<span v-if="loading" class="spinner"></span>
					<template v-else>Done</template>
				</button>
			</form>
		</template>
	</DialogBox>
</template>

<style scoped>
form {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 1.5rem;
}
</style>
