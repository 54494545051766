import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import LZString from 'lz-string';
import { Position } from 'monaco-editor';
import defaultCss from '@/assets/demos/offline';
import { projectDefaults } from '@/structures';

const storageMedium = window.localStorage;

export const useOfflineProjectStore = defineStore('offlineProject', () => {
	const canvasRatio    = useStorage('OFFLINE_CanvasRatio', ref(projectDefaults.canvasRatio), storageMedium, { listenToStorageChanges: true });
	const color          = useStorage('OFFLINE_Color',       ref(projectDefaults.backgroundColor), storageMedium, { listenToStorageChanges: true });
	const compressedCss  = useStorage('OFFLINE_CSS',         ref(LZString.compressToBase64(defaultCss)), storageMedium, { listenToStorageChanges: true });
	const date           = useStorage('OFFLINE_Date',        ref(Date.now()), storageMedium, { listenToStorageChanges: true });
	const editorRatio    = useStorage('OFFLINE_EditorRatio', ref(projectDefaults.editorRatio), storageMedium, { listenToStorageChanges: true });
	const flavour        = useStorage('OFFLINE_Flavour',     ref(projectDefaults.flavour), storageMedium, { listenToStorageChanges: true });
	const opacity        = useStorage('OFFLINE_Opacity',     ref(projectDefaults.backgroundAlpha), storageMedium, { listenToStorageChanges: true });
	const title          = useStorage('OFFLINE_Title',       ref(projectDefaults.title), storageMedium, { listenToStorageChanges: true });
	const zoom           = useStorage('OFFLINE_Zoom',        ref(projectDefaults.zoom), storageMedium, { listenToStorageChanges: true });
	const cursorPosition = ref(new Position(1, 0));
	const saved          = ref(true);

	const backgroundColor = computed((): string => {
		if (!color.value || !opacity.value) return 'unset';
		const hexAlpha = (opacity.value * 255).toString(16).split('.')[0].padStart(2, '0');
		return `${color.value}${hexAlpha}`;
	});

	const css = computed({
		get: (): string => LZString.decompressFromBase64(compressedCss.value) ?? '',
		set: (value: string) => { compressedCss.value = LZString.compressToBase64(value); }
	});

	const focusedSelector = computed((): string => {
		const above = css.value.split('\n').slice(0, cursorPosition.value.lineNumber).join('\n');
		const pattern = /(?<selector>[^{}]+){[^}]*}?/ig;
		const matches = [...above.matchAll(pattern)];
		const selector = matches.at(-1)?.groups?.selector?.trim();
		return selector ?? '';
	});

	return {
		backgroundColor,
		canvasRatio,
		color,
		css,
		date,
		editorRatio,
		flavour,
		opacity,
		title,
		zoom,
		cursorPosition,
		focusedSelector,
		saved
	};
});
