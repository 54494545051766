<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { formatDate, formatDateIso } from '@/utilities';

defineProps({
	title: { type: String, required: true },
	date: { type: Number, required: true },
	public: { type: Boolean, required: false },
	loading: { type: Boolean, required: false },
	selected: { type: Boolean, required: false }
});

const emit = defineEmits(['settings']);
</script>

<template>
	<button class="projectLink" :class="{ loading, selected }" data-journey-id="ProjectLink">
		<p>{{ title }}</p>
		<Icon :title="public ? 'Public' : 'Private'">{{ public ? 'public' : 'lock' }}</Icon>
		<time :datetime="formatDateIso(date)" :title="'Last edited on ' + formatDate(date)">{{ formatDate(date) }}</time>
		<button title="Settings" @click.stop="emit('settings')" data-journey-id="ProjectLinkSettings">
			<Icon>settings</Icon>
		</button>
	</button>
</template>

<style scoped>
.projectLink {
	display: grid;
	grid-template-columns: min-content 1fr min-content;
	grid-template-rows: auto auto;
	grid-template-areas:
		'title title settings'
		'icons time  settings';
	justify-content: flex-start;
	align-items: center;
	gap: 0 0.5ch;
	width: 100%;
	padding: 0.25em 1ch 0.4em;
	font-size: 1rem;
	background-color: var(--color-border-lighter);
	transition:
		0.1s ease background-color,
		0.1s ease border-color,
		0.1s ease opacity;
	cursor: pointer;
	user-select: none;
}
.projectLink:hover {
	background-color: var(--color-border-light);
}
.projectLink.selected,
.projectLink.selected:hover {
	background-color: var(--color-primary-harmony);
}
.projectLink.loading {
	opacity: 0.5;
	pointer-events: none;
}

.projectLink > p {
	grid-area: title;
	margin: 0;
	font-size: 0.9rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.projectLink > time {
	grid-area: time;
	margin: 0;
	font-size: small;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	opacity: 0.6;
}

.projectLink > .icon {
	grid-area: icons;
	font-size: small;
	opacity: 0.6;
}

.projectLink > button {
	grid-area: settings;
	align-self: center;
	opacity: 0.3;
	transition: 0.1s ease background-color, 0.1s ease opacity;
}
.projectLink:hover > button {
	opacity: 1;
}
</style>
