import { ref } from 'vue';
import { doc, writeBatch } from 'firebase/firestore';
import type { Firestore } from 'firebase/firestore';

class Batch {
	batch;
	lastCommit;
	operations;

	constructor (database: Firestore) {
		this.batch = writeBatch(database);
		this.lastCommit = Date.now();
		this.operations = 0;
	}
}

export class Batcher {
	private database: Firestore;
	private collection: string;
	private batches = new Map<string, Batch>();
	private watchdogInstances = new Array<number>();
	private watchdogRunning = false;
	private callbacks = {
		afterCommit: new Array<(id: string) => void>()
	};
	public pending = ref(false);

	constructor (database: Firestore, collection: string) {
		this.database = database;
		this.collection = collection;
		window.addEventListener('beforeunload', () => this.flush());
	}

	private commit (batch: Batch, id: string): void {
		void batch.batch.commit();
		this.batches.delete(id);
		this.updatePendingFlag();
		this.callbacks.afterCommit.forEach(callback => callback(id));
	}

	private watchdog (): void {
		const maxOperations = 400;
		const batchLifespan = 10000;
		const watchdogInterval = 2000;

		this.watchdogInstances.push(window.setInterval(() => {
			const lifespanThreshold = Date.now() - batchLifespan;
			this.batches.forEach((batch, id) => {
				if (batch.operations >= maxOperations || batch.lastCommit < lifespanThreshold) {
					this.commit(batch, id);
				}

				// Stop the watchdog if there is nothing to watch.
				if (this.batches.size <= 0) {
					for (const id of this.watchdogInstances) {
						window.clearInterval(id);
					}
					this.watchdogRunning = false;
				}
			});
		}, watchdogInterval));

		this.watchdogRunning = true;
	}

	private updatePendingFlag (): void {
		this.pending.value = this.batches.size > 0;
	}

	public write (id: string, property: string, value: NonNullable<any>): void {
		const maxOperations = 400;
		const batchLifespan = 10000;
		let batch = this.batches.get(id);
		if (!batch) {
			batch = new Batch(this.database);
			this.batches.set(id, batch);
			this.updatePendingFlag();
		}
		const docReference = doc(this.database, `${this.collection}/${id}`);
		const updatedValue: Record<string, NonNullable<any>> = {};
		updatedValue[property] = value;
		batch.batch.update(docReference, updatedValue);
		batch.operations++;
		if (batch.operations >= maxOperations || batch.lastCommit < Date.now() - batchLifespan) {
			this.commit(batch, id);
		}

		// Ensure the watchdog is running.
		if (!this.watchdogRunning) {
			this.watchdog();
		}
	}

	public flush (id?: string): void {
		if (!id) {
			this.batches.forEach(this.commit.bind(this));
			return;
		}
		let batch = this.batches.get(id);
		if (!batch) return;
		if (batch.operations > 0) {
			this.commit(batch, id);
		}
	}

	public afterCommit (callback: (id: string) => void): void {
		this.callbacks.afterCommit.push(callback);
	}
}
