import { userStore } from '@/main';
import { md5 } from './md5';

export function gravatar (size?: number): string {
	if (!userStore.raw) {
		return `https://www.gravatar.com/avatar/00000000000000000000000000000000.jpg?s=${size || 28}&d=mp`
	}
	const hash = md5(userStore.raw.email?.trim().toLowerCase() ?? '');
	return `https://www.gravatar.com/avatar/${hash}.jpg?s=${size || 28}&d=mp`;
}

export function gravatarFromHash (hash: string, size?: number): string {
	return `https://www.gravatar.com/avatar/${hash}.jpg?s=${size || 28}&d=mp`;
}
