export default `.example {
	padding: 0 3ch 2ch;
	color: white;
	font-family: 'Segoe UI', sans-serif;
	text-align: center;
	border: 1px solid #8884;
	border-radius: 1em;
}
.example h1 {
	content: 'Welcome!';
	color: transparent;
	font-weight: 600;
	background: linear-gradient(90deg, #ff5722 25%, #ff9800, #ffeb3b, #4caf50, #03a9f4, #3f51b5, #673ab7 95%);
	-webkit-background-clip: text;
	background-clip: text;
}
.example p {
	content: 'Go ahead, create something amazing ^-^';
}`;
