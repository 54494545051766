import type { RouteLocationNormalized, Router } from 'vue-router';
import { SessionEvent, SessionEventType, type SessionRaw, SubscriptionTier } from '@/structures';

function aspectRatio (): string {
	const numerator = window.innerWidth;
	const denominator = window.innerHeight;
	const gcd = (a: number, b: number): number => (b ? gcd(b, a % b) : a);
	const min = Math.max(gcd(numerator, denominator), 1);
	return `${numerator / min}/${denominator / min}`;
}

function findJourneyId (element: HTMLElement): string {
	let id = element.getAttribute('data-journey-id');
	let parent = element.parentElement;
	const loopLimit = 1000;
	let i = 0;
	while (!id && parent && i < loopLimit) {
		id = parent.getAttribute('data-journey-id');
		parent = parent.parentElement;
		i++;
	}
	return id || '-';
}

export class Session {
	private router?: Router;
	public journey = new Array<SessionEvent>();
	public tier = SubscriptionTier.Free;
	public time = 0;
	public active = false;

	constructor (router?: Router) {
		this.router = router;
	}

	private handleClickEvent (event: MouseEvent): void {
		if (!this.active) return;
		if (event.target instanceof HTMLElement) {
			const targetId = findJourneyId(event.target);
			this.journey.push(new SessionEvent(SessionEventType.Click, targetId));
		}
	}

	private handleNavEvent (to: RouteLocationNormalized): void {
		if (!this.active) return;
		this.journey.push(new SessionEvent(SessionEventType.Nav, to.fullPath));
	}

	public begin (): void {
		this.active = true;
		this.journey.push(new SessionEvent(SessionEventType.Begin, this.router?.currentRoute.value.fullPath ?? window.location.pathname));
		this.journey.push(new SessionEvent(SessionEventType.Resize, aspectRatio()));
		window.addEventListener('click', this.handleClickEvent.bind(this));
		this.router?.afterEach(this.handleNavEvent.bind(this));
	}

	public end (): void {
		this.journey.push(new SessionEvent(SessionEventType.End, this.router?.currentRoute.value.fullPath ?? window.location.pathname));
		this.time = Date.now();
		this.active = false;
		window.removeEventListener('click', this.handleClickEvent.bind(this));
	}

	public export (): SessionRaw {
		const events = this.journey.map((event) => ({ ...event }));
		return {
			journey: events,
			tier: this.tier,
			time: this.time
		};
	}
}
