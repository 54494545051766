<script setup lang="ts">
import { onUnmounted, ref } from 'vue';
import Icon from '@/components/Icon.vue';
import { Operation } from '@/modules/firebase/operations';
import router from '@/router';
import { wall } from '@/modules/firebase/walls';
import { RPCResponse } from '@/structures';

defineProps({
	background: { type: String, required: false, default: 'transparent' }
});

const loadingNewCollection = ref(false);
const loadingNewProject = ref(false);

async function createNewCollection (): Promise<void> {
	loadingNewCollection.value = true;
	const id = await Operation.create(Operation.Type.Collection, {});
	if (id && typeof id === 'string') {
		router.push(`collection/${id}`);
	} else {
	}
	loadingNewCollection.value = false;
}

async function createNewProject (): Promise<void> {
	loadingNewProject.value = true;
	const response = await Operation.create(Operation.Type.Project, {});
	if (response === RPCResponse.SubscriptionRequired().status) {
		wall.premium();
	} else if (response === RPCResponse.Success().status) {
		router.push('create');
	}
	loadingNewProject.value = false;
}

onUnmounted(() => {
	window.removeEventListener('click', close);
});
</script>

<template>
	<div class="createButton">
		<button title="New…"><Icon>add</Icon></button>

		<ul class="createMenu">
			<div class="background"></div>

			<li>
				<button @click.stop="createNewProject()">
					<span v-if="loadingNewProject" class="spinner"></span>
					<Icon v-else>data_object</Icon>
					<span>Project</span>
				</button>
			</li>
			<li>
				<button @click.stop="createNewCollection()">
					<span v-if="loadingNewCollection" class="spinner"></span>
					<Icon v-else>list</Icon>
					<span>Collection</span>
				</button>
			</li>
		</ul>
	</div>
</template>

<style scoped>
.createButton {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;

	& > button {
		height: 100%;
	}
}

.createMenu {
	--background: color-mix(in oklab, var(--color-background) 82%, v-bind(background));

	position: absolute;
	top: calc(100% + 1ch);
	right: -1rem;
	display: none;
	flex-flow: column nowrap;
	margin: 0;
	padding: 0.25ch;
	list-style: none;
	background: var(--color-background);
	border: 1px solid var(--color-border-light);
	border-radius: calc(var(--border-radius) + 0.25ch);
	box-shadow: 0 2px 15px -7px #0006;

	&::before {
		content: '';
		position: absolute;
		top: -1ch;
		width: 100%;
		height: 1ch;
	}

	&::after {
		content: '';
		position: absolute;
		top: calc(-1ch + 2px);
		right: 1.85rem;
		width: 1ch;
		height: 1ch;
		background: var(--background);
		border: inherit;
		transform: rotate(-45deg) translateY(50%);
		clip-path: polygon(
			0 0,
			100% 0,
			100% 100%
		);
		pointer-events: none;
	}

	& > .background {
		position: absolute;
		inset: 0;
		color: transparent;
		background-color: var(--background);
		border-radius: inherit;				
		pointer-events: none;
	}

	& li {
		display: flex;
		width: 100%;
	}

	& button {
		justify-content: flex-start;
		width: 100%;
		border: unset;
	}
}
html.dark .createMenu {
	background:
		linear-gradient(0deg, var(--color-border-light), var(--color-border-light)),
		linear-gradient(0deg, var(--color-background), var(--color-background));
}

.createButton:is(:hover, :focus-within) .createMenu {
	display: flex;
}
</style>
