import { ref } from 'vue';
import { defineStore } from 'pinia';

export enum Dialog {
	None,
	Account,
	Help,
	Login,
	Notification,
	Premium,
	Register,
	ResetPassword,
	Verify,
	Wizard
}

export const useDialogStore = defineStore('dialog', () => {
	/**
	 * The currently open dialog.
	 */
	const current = ref<Dialog>(Dialog.None);

	/**
	 * An additional message that will be shown with the currently open dialog.
	 */
	const dialogMessage = ref<string>('');

	/**
	 * Close any open dialog.
	 * @param dialog *(Optional)* Only close the specified dialog.
	 */
	function close (dialog = current.value): void {
		if (current.value === dialog) {
			current.value = Dialog.None;
			dialogMessage.value = '';
		}
	}

	/**
	 * Open a particular dialog.
	 */
	function open (dialog: Exclude<Dialog, Dialog.Notification>, message?: string): void;
	function open (dialog: Dialog.Notification, message: string): void;
	function open (dialog: Dialog, message = ''): void {
		dialogMessage.value = message;
		current.value = dialog;
	}

	return {
		close,
		current,
		Dialog,
		dialogMessage,
		open,
	};
});
