<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import css from '@/assets/demos/landing';
import { type Editor, getConfiguredEditor } from '@/modules/editor/config';
import HTMLGenerator from '@/modules/editor/generator';
import FocusBridge from '@/components/FocusBridge.vue';
import TextArrow from '@/components/TextArrow.vue';

let editor: Editor | undefined = undefined;
const art = ref<HTMLDivElement | null>(null);
const cssEditor = ref<HTMLDivElement | null>(null);
const editorDisplay = ref('none');
const editorPlaceholder = ref<HTMLDivElement | null>(null);
const generator = new HTMLGenerator();

async function loadEditor (): Promise<void> {
	// Editor.
	if (!cssEditor.value || editor) return;
	editorPlaceholder.value?.remove();
	editorDisplay.value = 'flex';
	editor = await getConfiguredEditor(
		cssEditor.value,
		css,
		'css',
		'vs-dark'
	);
	editor.model.onDidChangeContent(() => {
		if (!editor) return;
		const snapshot = editor.model.createSnapshot().read() ?? '';
		void generator.set(snapshot);
	});
}

onMounted(async () => {
	// Generator.
	if (!art.value) return;
	generator.applyShadowDom(art.value);
	void generator.set(css);
});

onBeforeUnmount(() => {
	editor?.remove();
});
</script>

<template>
	<div class="landingShowcase">
		<div ref="art" class="art" data-journey-id="LandingShowcaseArt"></div>
		<div
			class="code"
			@focusin="loadEditor()"
			@pointerenter="loadEditor()"
			data-journey-id="LandingShowcaseCode"
		>
			<p>CSS 🎉</p>
			<span class="spinner"></span>
			<FocusBridge label="Press enter to try the CSS editor">
				<div ref="cssEditor" class="cssEditor"></div>
			</FocusBridge>
			<div ref="editorPlaceholder" class="placeholder"></div>
		</div>
		<TextArrow>Try it!</TextArrow>
	</div>
</template>

<style scoped>
.landingShowcase {
	display: grid;
	grid-template-columns: 6.5fr 1fr 2.5fr;
	grid-template-rows: 4ch auto 4ch;
	justify-self: end;
	width: 100%;
	max-width: 1500px;
	height: 100%;
	min-height: 25em;
	max-height: 75ch;
	padding: 2%;
}

.landingShowcase::before {
	content: '';
	grid-area: 1 / 1 / 4 / 3;
	z-index: 1;
	margin: -4rem;
	border: 2rem solid var(--color-background);
	border-radius: calc(var(--border-radius-large) * 5);
	mask-image: radial-gradient(250% 250% at 50% 50%, transparent, black);
	opacity: 0;
	animation: fadeIn 0.5s 0.3s ease forwards;
	filter: opacity(0.24);
	pointer-events: none;
}
html.dark .landingShowcase::before {
	border-color: #555;
}

@media screen and (max-width: 68rem) {
	.landingShowcase {
		display: none;
	}
}

.landingShowcase .art {
	position: relative;
	z-index: 2;
	grid-area: 1 / 1 / 4 / 3;
	display: grid;
	place-items: center;
	width: 100%;
	height: 100%;
	background-color: #fff1;
	border-radius: 15px;
	box-shadow: 0 0 50px -20px #0008;
	contain: content;
	overflow: hidden;
	opacity: 0;
	animation: fadeIn 0.5s 0.3s ease forwards;
}
.landingShowcase .art::after {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: inherit;
	mix-blend-mode: overlay;
	box-shadow: var(--popout-shadow-large);
}
.landingShowcase.light .art {
	box-shadow: 0 0 50px -20px #0008, 0 0 400px -10px #0004;
}

.landingShowcase .code {
	position: relative;
	z-index: 3;
	grid-area: 2 / 2 / 3 / 4;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	background-color: #171717;
	background: radial-gradient(circle at 0 0, #171717, #0c0c0c);
	border-radius: var(--border-radius-large);
	box-shadow: 0 0 50px -20px #0008, 0 5px 20px -10px #0004;
	opacity: 0;
	animation: fadeIn 0.5s 0.5s ease forwards;
}
.landingShowcase .code::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 1.5rem;
	height: 1.5rem;
	background-color: #171717;
	box-shadow: var(--popout-shadow);
	transform: translateX(-50%);
	clip-path: polygon(0 0, 100% 0, 100% 100%);
}
.landingShowcase .code::after {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: inherit;
	border-top-left-radius: 0;
	box-shadow: var(--popout-shadow);
	pointer-events: none;
}

.landingShowcase .code > p {
	width: 100%;
	margin: 0;
	padding: 0.5ch 1.5ch;
	color: white;
}

.landingShowcase .code > .spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 1.4rem;
	opacity: 0.7;
	translate: -50% -50%;
	pointer-events: none;
}

.landingShowcase .code .cssEditor {
	position: relative;
	display: v-bind(editorDisplay);
	width: 100%;
	height: 100%;
	border-radius: 0 0 10px 10px;
	overflow: hidden;

	& > *:first-child {
		opacity: 0;
		animation: fadeIn 0.2s ease forwards;
	}
}

.landingShowcase .code .placeholder {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	background-image: url('@/assets/editor-placeholder.webp');
	background-size: cover;
	border-radius: 0 0 10px 10px;
	filter: saturate(1.4);
	overflow: hidden;
}

.landingShowcase .textArrow {
	position: absolute;
	z-index: 1;
	right: 9%;
	bottom: -8%;
	font-size: 0.75rem;
	transform: rotate(-10deg);
	opacity: 0;
	animation: fadeIn 0.5s 0.5s ease forwards;
	filter: opacity(0.6);
}
</style>
