<template>
	<div class="textArrow">
		<p><slot></slot></p>
		<div class="arrow">
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>
</template>

<style scoped>
.textArrow {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	align-items: center;
	gap: 2ch;
	color: inherit;
	font-family: cursive, sans-serif;
	font-size: inherit;
	transform-origin: 100% 0%;
}

.textArrow p {
	color: inherit;
	font-family: inherit;
	font-size: 3em;
}

.textArrow .arrow {
	width: 6.25em;
	height: 4.4em;
	color: inherit;
}
.textArrow .arrow span {
	position: absolute;
	display: block;
	color: inherit;
}
.textArrow .arrow span:nth-child(1) {
	width: 5.6em;
	height: 3.75em;
	border: 0.4em solid currentColor;
	border-radius: 50%;
	-webkit-mask-image: radial-gradient(at 100% 100%, #000 53%, transparent 54%);
	mask-image: radial-gradient(at 100% 100%, #000 53%, transparent 54%);
	transform: translate(-1.25em, -1.25em) rotate(-9deg);
}
.textArrow .arrow span:nth-child(2) {
	width: 0.35em;
	height: 1.7em;
	background-color: currentColor;
	border-radius: 1em;
	transform: translate(3.3em, -1em) rotate(15deg);
	transform-origin: 50% 0%;
}
.textArrow .arrow span:nth-child(3) {
	width: 0.35em;
	height: 1.7em;
	background-color: currentColor;
	border-radius: 1em;
	transform: translate(3.2em, -0.9em) rotate(-58deg);
	transform-origin: 50% 0%;
}
</style>
