import { ref } from 'vue';

class CacheItem<T> {
	data;
	updated;

	constructor (data: T) {
		this.data = data;
		this.updated = Date.now();
	}
}

/**
 * A generic cache.
 */
export class Cache<T> {
	public map = new Map<string, CacheItem<T>>();
	public lifespan;
	public loading = ref(false);
	public retriever;

	/**
	 * A generic cache.
	 * @param retriever A function to retrieve new data for the cache. It receives an array of item IDs and should return those same IDs along with their corresponding data.
	 * If this is undefined, expired items will not be refreshed automatically.
	 * @param lifespan `(Optional)` The maximum time (in seconds) any item can live in the cache. Requests made after this time will refresh the item.
	 * If this is set to `0` the item will never expire.
	 */
	constructor (retriever: ((request: Array<string>) => Promise<{ [id: string]: T }>) | undefined, lifespan = 600) {
		this.lifespan = lifespan * 1000;
		this.retriever = retriever;
	}

	/**
	 * Loads the data for multiple items from the server.
	 * @param ids An array of item IDs.
	 * @returns A map of item IDs and their corresponding info.
	 */
	private async loadMultiple (ids: Array<string>): Promise<Map<string, T>> {
		const output = new Map<string, T>();
		if (
			!Array.isArray(ids)
			|| ids.length <= 0
			|| !this.retriever
		) {
			return output;
		}
		this.loading.value = true;
		const result = await this.retriever(ids);
		if (!result) {
			return output;
		}
		for (const [id, info] of Object.entries(result)) {
			this.store(id, info);
			output.set(id, info);
		}
		this.loading.value = false;
		return output;
	}

	/**
	 * Loads the info for a single item from the server.
	 */
	private async load (id: string): Promise<T | undefined> {
		const result = await this.loadMultiple([ id ]);
		return result.get(id);
	}

	/**
	 * Gets the info for multiple items from the cache.
	 */
	public async getMultiple (ids: Array<string>): Promise<Map<string, T>> {
		const output = new Map<string, T>();
		const needsLoading = new Array<string>();
		for (const id of ids) {
			const item = this.map.get(id);
			if (item && (this.lifespan === 0 || item.updated > Date.now() - this.lifespan)) {
				output.set(id, item.data);
			} else {
				needsLoading.push(id);
			}
		}
		const loaded = await this.loadMultiple(needsLoading);
		for (const [id, item] of loaded) {
			output.set(id, item);
		}
		return output;
	}

	/**
	 * Gets the info for an item from the cache.
	 */
	public async get (id: string): Promise<T | undefined> {
		const item = this.map.get(id);
		if (item && (this.lifespan === 0 || item.updated > Date.now() - this.lifespan)) {
			return item.data;
		}
		return await this.load(id);
	}

	/**
	 * Stores multiple items in the cache.
	 */
	public storeMultiple (items: Map<string, T>): void {
		items.forEach((info, id) => this.store(id, info));
	}

	/**
	 * Stores an item in the cache.
	 */
	public store (id: string, info: T): void {
		this.map.set(id, new CacheItem(info));
	}

	/**
	 * Remove multiple items from the cache.
	 */
	public removeMultiple (ids: Array<string>): void {
		ids.forEach(id => this.remove(id));
	}

	/**
	 * Remove an item from the cache.
	 */
	public remove (id: string): void {
		this.map.delete(id);
	}
}
