import { dialogStore, userStore } from '@/main';
import { Role } from '@/structures';

type Wall = (success?: WallCallback, failure?: WallCallback) => boolean;
type WallCallback = () => (void | Promise<void>);

/**
 * Provides methods for walling off content.
 */
export const wall = {
	message: '',
	/**
	 * Create a login wall.
	 * @param success A function to call if the user is authenticated.
	 * @param failure A function to call if the user is **not** authenticated.
	 * @returns `true` if the user is authenticated, `false` if not.
	 */
	authenticated: (success?: WallCallback, failure?: WallCallback): boolean => {
		const authenticated = userStore.authenticated;
		if (authenticated) {
			wall.message = '';
			success?.();
			return true;
		} else {
			dialogStore.open(dialogStore.Dialog.Login, wall.message);
			wall.message = '';
			failure?.();
			return false;
		}
	},
	/**
	 * Create a verification wall.
	 * @param success A function to call if the user's email address has been verified.
	 * @param failure A function to call if the user's email address has **not** been verified.
	 * @returns `true` if the user's email address has been verified, `false` if not.
	 */
	verified: (success?: WallCallback, failure?: WallCallback): boolean => {
		const verified = userStore.raw?.emailVerified;
		if (verified) {
			wall.message = '';
			success?.();
			return true;
		} else {
			dialogStore.open(dialogStore.Dialog.Verify, wall.message);
			wall.message = '';
			failure?.();
			return false;
		}
	},
	/**
	 * Create a subscription wall.
	 * @param success A function to call if the user has an active subscription.
	 * @param failure A function to call if the user does **not** have an active subscription.
	 * @returns `true` if the user has an active subscription, `false` if not.
	 */
	premium: (success?: WallCallback, failure?: WallCallback): boolean => {
		const subscribed = userStore.subscribed;
		if (subscribed) {
			wall.message = '';
			success?.();
			return true;
		} else {
			dialogStore.open(dialogStore.Dialog.Premium, wall.message);
			wall.message = '';
			failure?.();
			return false;
		}
	},
	/**
	 * Create an admin wall.
	 * @param success A function to call if the user is an admin.
	 * @param failure A function to call if the user is **not** an admin.
	 * @returns `true` if the user is an admin, `false` if not.
	 */
	admin: (success?: WallCallback, failure?: WallCallback): boolean => {
		const admin = userStore.role >= Role.Administrator;
		if (admin) {
			success?.();
			return true;
		} else {
			failure?.();
			return false;
		}
	},
	/**
	 * Create a moderator wall.
	 * @param success A function to call if the user is a moderator.
	 * @param failure A function to call if the user is **not** a moderator.
	 * @returns `true` if the user is a moderator, `false` if not.
	 */
	moderator: (success?: WallCallback, failure?: WallCallback): boolean => {
		const moderator = userStore.role >= Role.Moderator;
		if (moderator) {
			success?.();
			return true;
		} else {
			failure?.();
			return false;
		}
	},
	/**
	 * Create a multi-stage wall.
	 * @param walls An array of wall functions.
	 * @param success A function to call if all walls pass.
	 * @param failure A function to call if **any** wall fails.
	 * @returns `true` if all walls pass, `false` if any do not.
	 */
	multiple: (walls: Array<Wall>, success?: WallCallback, failure?: WallCallback): boolean => {
		let result = true;
		for (const wall of walls) {
			result = result && wall();
			if (!result) {
				break;
			}
		}
		if (result) {
			success?.();
			return true;
		} else {
			failure?.();
			return false;
		}
	}
};
