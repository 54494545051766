import { ref, watch } from 'vue';
import { cssToHtml } from 'css-to-html';
import { Flavour } from '@/structures';

async function getRemoteStylesheet (url: string): Promise<string> {
	try {
		const response = await fetch(url);
		if (response.status !== 200) {
		  return '';
		}
		return await response.text();
	} catch {
		return '';
	}
}

export default class HTMLGenerator {
	public disableAnimations = ref(false);
	public input = ref('');
	public output = ref(document.createElement('body'));
	public loading = ref(false);
	public flavour = ref(Flavour.CSS);
	private shadowDomElements = new Array<HTMLElement>();
	private updateQueue = new Array<string>();

	constructor () {
		watch(this.disableAnimations, () => void this.set(this.input.value));
		watch(this.flavour, () => void this.set(this.input.value));
	}

	public async set (css: string): Promise<void> {
		this.input.value = css || '';
		const parsedCss = await this.parse(this.input.value);
		this.output.value = await this.build(parsedCss);
		await this.updateShadowDom(parsedCss);
	}

	public async parse (css: string): Promise<string> {
		let output = css;
		try {
			if (this.flavour.value === Flavour.LESS) {
				const { render } = await import('less');
				output = (await render(css)).css;
			} else if (this.flavour.value === Flavour.SCSS) {
				const { compileString } = await import('sass');
				output = compileString(css).css;
			}
		} finally {
			return output || '';
		}
	}
	
	public async build (css: string): Promise<HTMLBodyElement> {
		return await cssToHtml(css, { imports: 'include' });
	}

	public applyShadowDom (element: HTMLElement): void {
		if (!element.shadowRoot) {
			element.attachShadow({ mode: "open" });
		}
		if (this.shadowDomElements.indexOf(element) < 0) {
			this.shadowDomElements.push(element);
		}
		this.set(this.input.value);
	}

	public removeShadowDom (element: HTMLElement): void {
		if (!element.shadowRoot) return;
		element.shadowRoot.innerHTML = '';
		const index = this.shadowDomElements.indexOf(element);
		if (index < 0) return;
		this.shadowDomElements.splice(index, 1);
	}

	private async updateShadowDom (css: string): Promise<void> {
		if (this.loading.value) {
			this.updateQueue.push(css);
			return;
		}
		this.loading.value = true;
		// Prevent app styles being inherited.
		let styleResetString = ':host { all: initial; }';
		// Prevent animations from playing.
		if (this.disableAnimations.value) styleResetString += ' * { animation-play-state: paused !important; }';
		const styleReset = new CSSStyleSheet();
		styleReset.replaceSync(styleResetString);
		for (const { shadowRoot } of this.shadowDomElements) {
			if (!shadowRoot) {
				return;
			}
			const styleDocument = document.implementation.createHTMLDocument();
			const styleElement = document.createElement("style");
			styleElement.textContent = css;
			styleDocument.body.append(styleElement);
			const styleRules = styleElement.sheet?.cssRules ?? [];
			const imports = new Array<Promise<string>>();
			for (const rule of styleRules) {
				if (rule instanceof CSSImportRule && new URL(rule.href).pathname.endsWith(".css")) {
					imports.push(getRemoteStylesheet(rule.href));
				}
			}
			const importResults = await Promise.allSettled(imports);
			// Apply the custom styles.
			const stylesheet = new CSSStyleSheet();
			stylesheet.replaceSync(css);
			shadowRoot.adoptedStyleSheets = [styleReset, stylesheet];
			for (const result of importResults) {
				if (result.status === 'fulfilled' && result.value) {
					const remoteStylesheet = new CSSStyleSheet();
					remoteStylesheet.replaceSync(result.value);
					shadowRoot.adoptedStyleSheets.push(remoteStylesheet);
				}
			}
			// Populate the HTML elements.
			shadowRoot.innerHTML = '';
			for (const element of this.output.value.children) {
				shadowRoot.append(element.cloneNode(true));
			}
		}
		this.loading.value = false;
		// Flush the update queue.
		const queuedCss = this.updateQueue.pop();
		if (typeof queuedCss === "string") {
			this.updateQueue = new Array<string>();
			void this.updateShadowDom(queuedCss);
		}
	}

	public clear (): void {
		this.set('');
	}
}
