<script setup lang="ts">
import { computed, ref } from 'vue';
import router from '@/router';
import RegisterMenu from '@/components/RegisterMenu.vue';

const redirect = ref(false);

const show = computed({
	get: (): boolean => (true),
	set: (value: boolean) => (!value && !redirect.value && router.back())
});
</script>

<template>
	<RegisterMenu
		fullscreen
		@login="redirect = true, router.replace({ name: 'login' })"
		@success="redirect = true, router.push({ name: 'home' })"
		@verify="redirect = true, router.push({ name: 'verify' })"
		v-model="show"
	/>
</template>
