<script setup lang="ts">
import { RouterView } from 'vue-router';
import { dialogStore } from '@/main';
import router from '@/router';
import AccountMenu from '@/components/AccountMenu.vue';
import EditorWizard from '@/components/EditorWizard.vue';
import HelpMenu from '@/components/HelpMenu.vue';
import LoginMenu from '@/components/LoginMenu.vue';
import RefreshPrompt from '@/components/RefreshPrompt.vue';
import RegisterMenu from '@/components/RegisterMenu.vue';
import PremiumPrompt from '@/components/PremiumPrompt.vue';
import Notification from '@/components/Notification.vue';
import ResetPasswordMenu from '@/components/ResetPasswordMenu.vue';
import VerificationMenu from '@/components/VerificationMenu.vue';

// Allow dialogs to be closed with the `Escape` key.
window.addEventListener('keydown', (event) => {
	if (event.code === 'Escape') dialogStore.close();
});
</script>

<template>
	<RouterView />
	<AccountMenu
		v-if="dialogStore.current === dialogStore.Dialog.Account"
		:model-value="true"
		@update:model-value="dialogStore.close(dialogStore.Dialog.Account)"
		@delete="router.push('/delete-account')"
		@reset="dialogStore.open(dialogStore.Dialog.ResetPassword)"
		@verify="dialogStore.open(dialogStore.Dialog.Verify)"
	/>
	<EditorWizard
		v-if="dialogStore.current === dialogStore.Dialog.Wizard"
	/>
	<HelpMenu
		v-if="dialogStore.current === dialogStore.Dialog.Help"
		:model-value="true"
		@update:model-value="dialogStore.close(dialogStore.Dialog.Help)"
	/>
	<LoginMenu
		v-if="dialogStore.current === dialogStore.Dialog.Login"
		:model-value="true"
		@update:model-value="dialogStore.close(dialogStore.Dialog.Login)"
		@reset="dialogStore.open(dialogStore.Dialog.ResetPassword)"
		@signup="dialogStore.open(dialogStore.Dialog.Register)"
		@verify="dialogStore.open(dialogStore.Dialog.Verify)"
	/>
	<Notification
		v-if="dialogStore.current === dialogStore.Dialog.Notification"
		:message="dialogStore.dialogMessage"
		:model-value="true"
		@update:model-value="dialogStore.close(dialogStore.Dialog.Notification)"
	/>
	<PremiumPrompt
		v-if="dialogStore.current === dialogStore.Dialog.Premium"
		:model-value="true"
		@update:model-value="dialogStore.close(dialogStore.Dialog.Premium)"
	/>
	<RegisterMenu
		v-if="dialogStore.current === dialogStore.Dialog.Register"
		:model-value="true"
		@update:model-value="dialogStore.close(dialogStore.Dialog.Register)"
		@login="dialogStore.open(dialogStore.Dialog.Login)"
		@verify="dialogStore.open(dialogStore.Dialog.Verify)"
	/>
	<ResetPasswordMenu
		v-if="dialogStore.current === dialogStore.Dialog.ResetPassword"
		:model-value="true"
		@update:model-value="dialogStore.close(dialogStore.Dialog.ResetPassword)"
		@success="dialogStore.open(dialogStore.Dialog.Notification, 'Password successfully reset.')"
	/>
	<VerificationMenu
		v-if="dialogStore.current === dialogStore.Dialog.Verify"
		:model-value="true"
		@update:model-value="dialogStore.close(dialogStore.Dialog.Verify)"
		@success="dialogStore.open(dialogStore.Dialog.Notification, 'Email verified.')"
	/>
	<RefreshPrompt />
</template>
