<script setup lang="ts">
import { ref, watch } from 'vue';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Firebase from '@/modules/firebase/core';
import DialogBox from '@/components/DialogBox.vue';
import { dialogStore, userStore } from '@/main';

defineProps({ modelValue: { type: Boolean, required: true } });
const emit = defineEmits(['update:modelValue', 'reset', 'signup', 'success', 'verify']);
const email = ref('');
const password = ref('');
const loading = ref(false);
const error = ref(false);

const hide = (): void => emit('update:modelValue', false);

async function login (): Promise<void> {
	loading.value = true;
	try {
		const credential = await signInWithEmailAndPassword(Firebase.auth, email.value, password.value);
		loading.value = false;
		if (!credential.user.emailVerified) {
			emit('verify');
		} else {
			emit('success');
		}
		hide();
	} catch (e: any) {
		loading.value = false;
		error.value = true;
		console.warn('Failed to login:', e.code, e.message);
	}
}

watch(email, () => { error.value = false; });
watch(password, () => { error.value = false; });
watch(() => userStore.authenticated, authenticated => authenticated && hide(), { immediate: true });
</script>

<template>
	<DialogBox
		title="Login"
		:message="dialogStore.dialogMessage"
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="LoginMenu"
	>
		<template v-slot:default>
			<form @submit.prevent="login()">
				<label for="loginEmail">Email
					<input
						type="email"
						id="loginEmail"
						name="loginEmail"
						autocomplete="email"
						required
						v-model="email"
					>
				</label>
				<label for="loginPassword">Password
					<input
						type="password"
						id="loginPassword"
						name="loginPassword"
						autocomplete="current-password"
						required
						v-model="password"
					>
				</label>
				<p v-if="error" style="margin: 0;font-size: small;">
					<i style="color: var(--color-danger);">Incorrect email/password. Please try again.</i>
					<br>
					<button
						:disabled="loading"
						@click="{ emit('reset'); hide(); }"
						data-journey-id="LoginMenuForgotPassword"
					>Forgot your password?</button>
				</p>
				<button type="submit" :disabled="loading" data-journey-id="LoginMenuSubmit">
					<span v-if="loading" class="spinner"></span>
					<template v-else>Login</template>
				</button>
			</form>
		</template>
		<template v-slot:bottom>
			<p>Don't have an account?</p>
			<button
				:disabled="loading"
				@click.stop="{ emit('signup'); hide(); }"
				data-journey-id="LoginMenuRegister"
			>Sign up</button>
		</template>
	</DialogBox>
</template>

<style scoped>
form {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 1.5rem;
}
form label {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 0.5ch;
	width: 100%;
}
</style>
