<script setup lang="ts">
import DialogBox from '@/components/DialogBox.vue';
import PremiumCard from '@/components/PremiumCard.vue';

defineProps({ modelValue: { type: Boolean, required: true } });
const emit = defineEmits(['update:modelValue']);
</script>

<template>
	<DialogBox
		title=""
		dense
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="PremiumPrompt"
	>
		<template v-slot:default>
			<PremiumCard style="max-width: unset;" />
		</template>
		<template v-slot:bottom>
			<p>Already subscribed?</p>
			<a
				href="mailto:support@cascades.app"
				target="_blank"
				rel="noreferrer"
				class="button"
				data-journey-id="PremiumPromptSupport"
			>Contact support</a>
		</template>
	</DialogBox>
</template>

<style scoped>
</style>
