import { ref } from 'vue';
import { defineStore } from 'pinia';

export const flags = [
	'collections',
	'comments',
	'components',
	'features',
	'images',
	'likes',
	'projects',
	'reports',
	'sessions',
	'streams',
	'suggestions'
] as const;

export const useFlagsStore = defineStore('flags', () => {
	return {
		collections: ref(false),
		comments: ref(false),
		components: ref(false),
		features: ref(true),		// Should be `true` by default since changing it causes layout shifts.
		images: ref(false),
		likes: ref(false),
		projects: ref(false),
		reports: ref(false),
		sessions: ref(false),
		streams: ref(false),
		suggestions: ref(false)
	};
});
