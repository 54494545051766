<script setup lang="ts">
import { ref, watch } from 'vue';
import DialogBox from '@/components/DialogBox.vue';

defineProps({ modelValue: { type: Boolean, required: true } });
const emit = defineEmits(['update:modelValue', 'error', 'success']);
const email = ref('');
const loading = ref(false);
const error = ref(false);

const hide = (): void => emit('update:modelValue', false);

async function submit (): Promise<void> {
	loading.value = true;
	loading.value = false;
}

watch(email, () => { error.value = false; });
</script>

<template>
	<DialogBox
		title="Reset Password"
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="ResetPasswordMenu"
	>
		<template v-slot:default>
			<form @submit.prevent="submit()">
				<p>Please enter your email address. We'll send you a link to reset your password.</p>
				<label for="loginEmail">Email
					<input
						type="email"
						id="loginEmail"
						name="loginEmail"
						autocomplete="email"
						v-model="email"
					>
				</label>
				<p v-if="error" style="margin: 0;color: var(--color-danger);font-size: small;"><i>An error occurred, please contact support.</i></p>
				<div class="row">
					<button
						:disabled="loading"
						style="width: 50%;"
						data-journey-id="ResetPasswordMenuCancel"
						@click.stop.prevent="hide()"
					>
						Cancel
					</button>
					<button
						type="submit"
						:disabled="loading"
						style="width: 50%;"
						data-journey-id="ResetPasswordMenuSubmit"
					>
						<span v-if="loading" class="spinner"></span>
						<template v-else>Reset</template>
					</button>
				</div>
			</form>
		</template>
	</DialogBox>
</template>

<style scoped>
form {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 3rem;
}
form label {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 0.5ch;
	width: 100%;
}
form .row {
	display: flex;
	flex-flow: row nowrap;
	gap: 2ch;
}
form p {
	margin: 0;
}
form p:first-child {
	margin-block-start: 1em;
}
</style>
