<script setup lang="ts">
import { computed, ref } from 'vue';
import router from '@/router';
import LoginMenu from '@/components/LoginMenu.vue';

const redirect = ref(false);

const show = computed({
	get: (): boolean => (true),
	set: (value: boolean) => (!value && !redirect.value && router.back())
});
</script>

<template>
	<LoginMenu
		fullscreen
		@reset="redirect = true, router.replace({ name: 'reset-password' })"
		@signup="redirect = true, router.replace({ name: 'register' })"
		@success="redirect = true, router.push({ name: 'home' })"
		@verify="redirect = true, router.push({ name: 'verify' })"
		v-model="show"
	/>
</template>
