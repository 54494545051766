import type { DataConverterRX, DataConverterTX } from './meta';
import { matchedAssign } from './other';

/**
 * A user-created collection of projects.
 */
export class Collection {
	owner: string;
	projects = new Array<string>();
	title = 'Untitled';

	constructor (owner: string) {
		this.owner = owner;
	}

	static fromFirestore: DataConverterRX<Collection> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new Collection(''), data);
	}

	static toFirestore: DataConverterTX<Collection> = (collection) => {
		return { ...collection };
	}
}
